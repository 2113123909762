import React, { useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Button } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import NewLocationModal from '../components/NewLocationModal';
import { useAuthContext } from '../hooks/useAuthContext';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import EditLocationModal from '../components/EditLocationModal';
import localeText from '../utils/agGridLocale';


const fetchLocations = async ({ queryKey }) => {
    const merchantID = queryKey[1];
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/locations`, {
        method: 'GET',
        credentials: 'include'
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Neuspelo preuzimanje lokacija: ${errorDetails.message}`);
    }
    return response.json();
};

const LocationsTable = ({ merchantID }) => {
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const queryClient = useQueryClient();
    const { user } = useAuthContext()

    const { data: locations, isLoading, isError } = useQuery({
        queryKey: ['locations', merchantID],
        queryFn: fetchLocations
    });

    const mutation = useMutation({
        mutationFn: async (location) => {
            const isEditing = location.id !== undefined;
            const url = isEditing
                ? `${process.env.REACT_APP_API_BASE_URL}/merchants/locations/${location.id}`
                : `${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/newlocation`;

            const method = isEditing ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(location),
            });
            if (!response.ok) {
                const errorDetails = await response.text();
                throw new Error(`Greška prilikom čuvanja lokacije: ${errorDetails}`);
            }
            return response.json();
        },
        onMutate: async (newLocation) => {
            await queryClient.cancelQueries(['locations', merchantID]);
            const previousLocations = queryClient.getQueryData(['locations', merchantID]);

            queryClient.setQueryData(['locations', merchantID], (old) => {
                const optimisticLocation = { ...newLocation, id: newLocation.id || Date.now() };
                return newLocation.id
                    ? old.map((loc) => (loc.id === newLocation.id ? optimisticLocation : loc))
                    : [...(old || []), optimisticLocation];
            });

            return { previousLocations };
        },
        onSuccess: (data) => {
            if (data.updatedLocation) {
                toast.success(`Lokacija "${data.updatedLocation.name}" je uspešno izmenjena!`);
            } else if (data.newLocation) {
                toast.success(`Lokacija "${data.newLocation.name}" je uspešno kreirana!`);
            } else {
                toast.success('Lokacija je uspešno obrađena!');
            }
        },
        onError: async (err, location, context) => {
            try {
                const errorResponse = await err.json();
                if (errorResponse.missingFields) {
                    toast.error(`Polja koja nedostaju su: ${errorResponse.missingFields.join(', ')}`);
                } else if (errorResponse.error) {
                    toast.error(errorResponse.error);
                } else {
                    toast.error(
                        location.id
                            ? `Neuspešna izmena lokacije "${location.name}". Molim vas pokušajte ponovo.`
                            : `Neuspešno kreiranje nove lokacije "${location.name}". Molim vas pokušajte ponovo.`
                    );
                }
            } catch (parseError) {
                toast.error(
                    location.id
                        ? `Neuspešna izmena lokacije "${location.name}". Molim vas pokušajte ponovo.`
                        : `Neuspešno kreiranje nove lokacije "${location.name}". Molim vas pokušajte ponovo.`
                );
            }
            if (context?.previousLocations) {
                queryClient.setQueryData(['locations'], context.previousLocations);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['locations', merchantID]);
        },
    });

    const columns = useMemo(() => {
        const baseColumns = [
            { headerName: 'ID lokacije', field: 'id', filter: true },
            { headerName: 'Naziv prodavnice ', field: 'name', filter: true },
            { headerName: 'Adresa prodavnice', field: 'address', filter: true },
            { headerName: 'Korisničko ime', field: 'username', filter: true },
            {
                headerName: 'Fizička prodavnica',
                flex: 1,
                field: 'real_location',
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            disabled
                            checked={params.value}
                        />
                    );
                },
            },
        ];

        if (user.role === 'INTESA_SUPER_ADMIN') {
            baseColumns.push({
                headerName: '',
                cellRenderer: (params) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaEdit
                            style={{
                                cursor: 'pointer',
                                marginTop: '5',
                                marginLeft: '10',
                                color: '#0d6efd',
                                transition: 'color 0.3s',
                            }}
                            size={26}
                            onMouseEnter={(e) => (e.currentTarget.style.color = '#084298')}
                            onMouseLeave={(e) => (e.currentTarget.style.color = '#0d6efd')}
                            onClick={() => handleEditClick(params.data)}
                        />
                    </div>
                ),
            });
        }

        return baseColumns;
    }, [user.role]);


    const handleEditClick = (location) => {
        setCurrentLocation(location);
        setShowEditModal(true);
    };

    const handleCreateLocation = (newLocation) => {
        mutation.mutate(newLocation);
        setShowNewModal(false);
    };

    const handleEditLocation = (updatedLocation) => {
        mutation.mutate(updatedLocation);
        setShowEditModal(false);
    };

    return (
        <div className='locations'>
            {isLoading && <div>Ucitava se...</div>}
            {isError && <div>Greška prilikom učitavanja lokacija.</div>}
            {!isLoading && !isError && (
                <div className="ag-theme-quartz" style={{ height: 400 }}>
                    {locations && locations.length > 0 ? (
                        <AgGridReact
                            rowData={locations}
                            columnDefs={columns}
                            pagination={true}
                            paginationPageSize={20}
                            enableCellTextSelection={true}
                            localeText={localeText}
                        />
                    ) : (
                        <div>Nema dostupnih lokacija.</div>
                    )}
                </div>
            )}
            <NewLocationModal
                show={showNewModal}
                handleClose={() => setShowNewModal(false)}
                onSubmit={handleCreateLocation}
            />

            <EditLocationModal
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                locationData={currentLocation}
                onSubmit={handleEditLocation}
            />
            {user?.role === 'INTESA_SUPER_ADMIN' && (
                <div className="new-offer-button" style={{ marginTop: '20px' }}>
                    <Button variant="primary" onClick={() => setShowNewModal(true)}>
                        Nova lokacija
                    </Button>
                </div>
            )}
        </div>
    );
};

export default LocationsTable;

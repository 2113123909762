import * as XLSX from "xlsx";

export const exportVisibleDataToExcel = (gridRef) => {
    if (!gridRef.current) {
        console.warn("Grid reference not available.");
        return;
    }

    const visibleColumns = gridRef.current.api.getAllDisplayedColumns().map(col => col.getColId());

    const columnDefsMap = {
        marketplace: "Marketplace",
        provider: "Voucher Provider",
        productCode: "Product Code",
        code: "Voucher Code",
        userReference: "User Reference",
        status: "Status",
        createdBy: "Created By",
        createdAt: "Created At",
        updatedAt: "Updated At",
        expirationDate: "Expiration Date",
        userAssignedTime: "User Assigned Time",
        activationTime: "Activation Time",
        activationLocation: "Activation Location"
    };

    const dateFields = ["createdAt", "updatedAt", "expirationDate", "userAssignedTime", "activationTime"];

    const visibleRowData = [];

    gridRef.current.api.forEachNodeAfterFilter((node) => {
        const filteredData = {};
        visibleColumns.forEach(col => {
            let value = node.data[col];

            if (dateFields.includes(col) && value) {
                const date = new Date(value);
                date.setUTCDate(date.getUTCDate() + 1);
                value = date.toISOString().split("T")[0];
            }

            filteredData[columnDefsMap[col] || col] = value;
        });
        visibleRowData.push(filteredData);
    });

    const ws = XLSX.utils.json_to_sheet(visibleRowData);

    const headerRow = visibleColumns.map(col => columnDefsMap[col] || col);
    XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: "A1" });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "VoucherData");

    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    const fileName = `VoucherData_${formattedDate}.xlsx`;

    XLSX.writeFile(wb, fileName);
};
const localeText = {
    page: "Strana",
    to: "-",
    of: "od",
    more: "više",
    toNextPage: "Idi na sledeću stranu",
    toPreviousPage: "Idi na prethodnu stranu",
    lastPage: "Poslednja strana",
    firstPage: "Prva strana",
    loadingOoo: "Učitavanje...",
    pageSize: "Veličina stranice",
    filterOoo: "Filtriraj...",
    equals: "Jednako",
    notEqual: "Nije jednako",
    lessThan: "Manje od",
    greaterThan: "Veće od",
    contains: "Sadrži",
    notContains: "Ne sadrži",
    startsWith: "Počinje sa",
    endsWith: "Završava se sa",
    before: "Pre",
    after: "Posle",
    inRange: "Između",
    blank: "Prazno polje",
    notBlank: "Popunjeno polje",
    true: "Da",
    false: "Ne",
    goToPage: "Idi na stranu",
    pageSizeSelectorLabel: "",
    totalAndFilteredRows: "Prikazano {0} od {1} zapisa",
    noRowsToShow: "Nema podataka za prikaz",
};

export default localeText;
import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EditOfferModal from '../components/EditOfferModal';
import { useAuthContext } from '../hooks/useAuthContext';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import localeText from '../utils/agGridLocale';

const fetchOffers = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/alloffers`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Greška prilikom učitavanja podataka.');
    }
    return response.json();
};

const fetchOfferDetails = async (offerId) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/offer/${offerId}`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Greška prilikom učitavanja detalja ponude.');
    }
    return response.json();
};

const AllOffers = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [showEditOfferModal, setShowEditOfferModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const queryClient = useQueryClient();

    const { data: offers, isLoading, isError } = useQuery({
        queryKey: ['offers'],
        queryFn: fetchOffers,
    });

    const mutation = useMutation({
        mutationFn: async (offer) => {
            const url = `${process.env.REACT_APP_API_BASE_URL}/merchants/offer/${offer.offer_id}`;
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(offer),
            });

            if (!response.ok) {
                throw new Error(`Greška prilikom izmene ponude.`);
            }
            return response.json();
        },
        onMutate: async (offer) => {
            await queryClient.cancelQueries(['offers']);

            const previousOffers = queryClient.getQueryData(['offers']);
            queryClient.setQueryData(['offers'], (old) =>
                old.map((existingOffer) =>
                    existingOffer.offer_id === offer.offer_id ? { ...existingOffer, ...offer } : existingOffer
                )
            );

            return { previousOffers };
        },
        onSuccess: (data) => {
            toast.success(
                data.updatedOffer
                    ? `Ponuda "${data.updatedOffer.name}" je uspešno izmenjena!`
                    : 'Ponuda je uspešno izmenjena!'
            );
        },
        onError: async (err, offer, context) => {
            toast.error(
                offer.offer_id
                    ? `Greška prilikom izmene ponude "${offer.name}". Molim vas pokušajte ponovo.`
                    : 'Neuspešno procesiranje zahteva. Molim vas pokušajte ponovo.'
            );

            if (context?.previousOffers) {
                queryClient.setQueryData(['offers'], context.previousOffers);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['offers']);
        },
    });

    const handleEditClick = async (offerId) => {
        try {
            const offerDetails = await fetchOfferDetails(offerId);
            setSelectedOffer({ ...offerDetails, offer_id: offerId });
            setShowEditOfferModal(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    const handleSaveOffer = (updatedOffer) => {
        mutation.mutate(updatedOffer);
        setShowEditOfferModal(false);
        setSelectedOffer(null);
    };

    const columns = [
        { headerName: 'Trgovac', field: 'service_title', flex: 1, filter: true, floatingFilter: true },
        { headerName: 'ID ponude', field: 'offer_id', flex: 1, filter: true, floatingFilter: true },
        { headerName: 'Naziv ponude', field: 'offer_title', flex: 1, filter: true, floatingFilter: true },
        { headerName: 'Tip ponude', field: 'offer_type', flex: 1, filter: true, floatingFilter: true },
        {
            headerName: 'Početak ponude',
            flex: 1,
            field: 'valid_from',
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
        },
        {
            headerName: 'Kraj ponude',
            flex: 1,
            field: 'valid_to',
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
        },
        {
            headerName: 'Maloprodajni objekat',
            flex: 1,
            field: 'physical_store',
            cellRenderer: (params) => (
                <input type="checkbox" disabled checked={params.value} />
            ),
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [true, false],
                cellRenderer: (params) => (params.value ? 'Yes' : 'No'),
                textFormatter: (value) => (value === true ? 'Yes' : 'No'),
                newRowsAction: 'keep',
            },
        },
        { headerName: 'Ukupan broj kupona', field: 'voucherDetails.totalVouchers', flex: 1 },
        { headerName: 'Aktivirani kuponi', field: 'voucherDetails.userAssignedVouchers', flex: 1 },
        { headerName: 'Iskorišćeni kuponi', field: 'voucherDetails.activeVouchers', flex: 1 },
        {
            headerName: '',
            flex: 1,
            cellRenderer: (params) => {
                const isInfoOffer = params.data.offer_type === 'Info';
                const offerId = params.data.offer_id;

                const dynamicUrl = `https://stage-intesa.payteam.live/offer-page?client=rsintesa&offerId=${offerId}&cif=12345`;

                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {user.role === 'INTESA_SUPER_ADMIN' && (
                            <FaEdit
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '5px',
                                    marginLeft: '5px',
                                    color: '#0d6efd',
                                    transition: 'color 0.3s',
                                }}
                                size={26}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#084298')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#0d6efd')}
                                onClick={() => handleEditClick(params.data.offer_id)}
                            />
                        )}

                        {user.role === 'INTESA_SUPER_ADMIN' && (
                            <div
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    color: '#f57c00',
                                    fontWeight: 'bold',
                                    fontSize: '24px',
                                    transition: 'color 0.3s',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#e65100')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#f57c00')}
                                onClick={() => window.open(dynamicUrl, '_blank')}
                            >
                                M
                            </div>
                        )}
                        {!isInfoOffer && (
                            <FaEye
                                style={{
                                    cursor: 'pointer',
                                    color: '#6c757d',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    transition: 'color 0.3s',
                                    marginTop: '5',
                                }}
                                size={26}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#495057')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#6c757d')}
                                onClick={() => navigate(`/merchants/${params.data.service_id}/offers/${params.data.offer_id}`)}
                            />
                        )}
                    </div>
                );
            },
        }
    ];

    return (
        <div className="offers">
            <div style={{ marginTop: '70px', marginLeft: '10px', marginBottom: '20px', textAlign: 'left', fontSize: '40px', fontWeight: 'bold' }}>
                Ponude
            </div>
            {isLoading && <div>Učitava se...</div>}
            {isError && <div>Greška prilikom učitanjava ponuda.</div>}
            {!isLoading && !isError && (
                <div className="ag-theme-quartz" style={{ height: 1000 }}>
                    {offers && offers.length > 0 ? (
                        <AgGridReact
                            rowData={offers}
                            columnDefs={columns}
                            pagination={true}
                            paginationPageSize={50}
                            enableCellTextSelection={true}
                            localeText={localeText}
                        />
                    ) : (
                        <div>Nema dostupnih ponuda.</div>
                    )}
                </div>
            )}
            <EditOfferModal
                show={showEditOfferModal}
                handleClose={() => setShowEditOfferModal(false)}
                onSubmit={handleSaveOffer}
                offerData={selectedOffer}
            />
        </div>
    );
};

export default AllOffers;

import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
            <h1>404 - Stranica nije pronađena.</h1>
            <p>Stranica koju tražite ne postoji.</p>
            <Link to="/" style={{ textDecoration: 'none', color: 'blue' }}>
                Povratak na početnu stranicu.
            </Link>
        </div>
    );
};

export default ErrorPage;

import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Offers from './Offers';
import Locations from './Locations';

function OffersLocationsTabs({ merchantID }) {
    const [key, setKey] = useState('offers');

    return (
        <div className='offers-locations-tabs'>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="offers" title="Ponude">
                    <Offers merchantID={merchantID} />
                </Tab>
                <Tab eventKey="locations" title="Lokacije">
                    <Locations merchantID={merchantID} />
                </Tab>
            </Tabs>
        </div>
    );
}

export default OffersLocationsTabs;

import React, { useState } from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { FaEdit } from 'react-icons/fa';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import NewMerchantModal from '../components/NewMerchantModal';
import EditMerchantModal from '../components/EditMerchantModal';
import { useAuthContext } from '../hooks/useAuthContext'
import { toast } from 'react-toastify';
import '../styles/ListOfMerchants.css';

const fetchMerchants = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to fetch merchants');
    }
    return response.json();
};


const fetchMerchantDetails = async (service_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/details/${service_id}`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to fetch merchant details.');
    }
    return response.json();
};

const MerchantsList = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { user } = useAuthContext();

    const [showNewMerchantModal, setShowNewMerchantModal] = useState(false);
    const [showEditMerchantModal, setShowEditMerchantModal] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState(null);

    const { data: merchants, error, isLoading } = useQuery({
        queryKey: ['merchants'],
        queryFn: fetchMerchants,
    });

    const mutation = useMutation({
        mutationFn: async (merchant) => {
            const isEditing = merchant.id !== undefined;

            const url = isEditing
                ? `${process.env.REACT_APP_API_BASE_URL}/merchants/details/${merchant.id}`
                : `${process.env.REACT_APP_API_BASE_URL}/merchants/newmerchant`;
            const method = isEditing ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(merchant),
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                throw errorResponse;
            }
            return response.json();
        },
        onSuccess: (data) => {
            if (data.updatedMerchant) {
                toast.success(`Merchant "${data.updatedMerchant.title}" has been successfully updated!`);
            } else if (data.newMerchant) {
                toast.success(`Merchant "${data.newMerchant.title}" has been successfully created!`);
            } else {
                toast.success('Merchant has been successfully processed!');
            }
            queryClient.invalidateQueries({ queryKey: ['merchants'] });
            setShowNewMerchantModal(false);
            setShowEditMerchantModal(false);
        },
        onError: async (err, merchant, context) => {
            try {
                if (err.missingFields) {
                    toast.error(`Missing fields: ${err.missingFields.join(', ')}`);
                } else if (err.error) {
                    toast.error(err.error);
                } else {
                    toast.error(
                        merchant.id
                            ? `Failed to update the merchant "${merchant.title}". Please try again.`
                            : `Failed to create the new merchant "${merchant.title}". Please try again.`
                    );
                }
            } catch (parseError) {
                console.error('Error parsing the error response:', parseError);
                toast.error('An unexpected error occurred. Please try again.');
            }
            if (context?.previousMerchants) {
                queryClient.setQueryData(['merchants'], context.previousMerchants);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['merchants']);
        },
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const groupedMerchants = merchants.reduce((acc, merchant) => {
        const categoryKey = `${merchant.category_id}-${merchant.category_title}`;
        if (!acc[categoryKey]) {
            acc[categoryKey] = {
                category_id: merchant.category_id,
                category_title: merchant.category_title,
                merchants: [],
            };
        }
        acc[categoryKey].merchants.push(merchant);
        return acc;
    }, {});

    const categories = Object.keys(groupedMerchants).map((key) => groupedMerchants[key].category_title);

    const handleNewMerchantSubmit = (newMerchant) => {
        mutation.mutate(newMerchant);
    };

    const handleEditClick = async (service_id) => {
        try {
            const merchantDetails = await fetchMerchantDetails(service_id);
            setSelectedMerchant(merchantDetails);
            setShowEditMerchantModal(true);
        } catch (error) {
            console.error('Error fetching merchant details:', error);
        }
    };

    const handleEditMerchantSubmit = (updatedMerchant) => {
        mutation.mutate(updatedMerchant);
    };

    return (
        <div className="merchants-page">
            <Sidebar categories={categories} />
            <Container className="merchant-list">
                {Object.values(groupedMerchants).map(({ category_id, category_title, merchants }) => (
                    <div key={category_id} id={category_title} className="category-section">
                        <h2>{category_title}</h2>
                        <Row className="g-4 mb-4">
                            {merchants.map((merchant) => (
                                <Col key={merchant.service_id} xs={12} md={6} lg={4} className="d-flex justify-content-center">
                                    <Card style={{ width: '100%', cursor: 'pointer' }} onClick={() => navigate(`/merchants/${merchant.service_id}`)}>
                                        <Card.Body className="d-flex align-items-center justify-content-between">
                                            <img
                                                src={merchant.service_small_image_url || 'default-image.png'}
                                                alt={merchant.service_title}
                                                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                className="me-3"
                                            />

                                            <h5 className="mb-0 flex-grow-1 fw-bold">{merchant.service_title}</h5>
                                            {user.role === 'INTESA_SUPER_ADMIN' && (
                                                <FaEdit
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#0d6efd',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    size={26}
                                                    onMouseEnter={(e) => (e.currentTarget.style.color = '#084298')}
                                                    onMouseLeave={(e) => (e.currentTarget.style.color = '#0d6efd')}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditClick(merchant.service_id);
                                                    }}
                                                />
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
            </Container>
            {user.role === 'INTESA_SUPER_ADMIN' && (
                <Button
                    variant="primary"
                    className="add-merchant-button"
                    onClick={() => setShowNewMerchantModal(true)}
                >
                    Novi trgovac
                </Button>
            )}
            <NewMerchantModal
                show={showNewMerchantModal}
                onHide={() => setShowNewMerchantModal(false)}
                onSubmit={handleNewMerchantSubmit}
            />
            <EditMerchantModal
                show={showEditMerchantModal}
                handleClose={() => setShowEditMerchantModal(false)}
                onSubmit={handleEditMerchantSubmit}
                merchantData={selectedMerchant}
            />
        </div>
    );
};

export default MerchantsList;

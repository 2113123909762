import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaEdit, FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import NewOfferModal from '../components/NewOfferModal';
import EditOfferModal from './EditOfferModal';
import { useAuthContext } from '../hooks/useAuthContext';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import localeText from '../utils/agGridLocale';


const fetchOffers = async ({ queryKey }) => {
    const merchantID = queryKey[1];
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/offers`, {
        method: 'GET',
        credentials: 'include'
    });
    if (!response.ok) {
        throw new Error('Neuspelo preuzimanje ponuda.');
    }
    return response.json();
};

const fetchOfferDetails = async (offerId) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/offer/${offerId}`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Neuspelo preuzimanje detalja ponude.');
    }
    return response.json();
};

const OffersTable = ({ merchantID }) => {
    const { user } = useAuthContext()
    const navigate = useNavigate();
    const [showNewOfferModal, setShowNewOfferModal] = useState(false);
    const [showEditOfferModal, setShowEditOfferModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const queryClient = useQueryClient();


    const { data: offers, isLoading, isError } = useQuery({
        queryKey: ['offers', merchantID],
        queryFn: fetchOffers
    });

    const mutation = useMutation({
        mutationFn: async (offer) => {
            const isEditing = offer.offer_id !== undefined;

            const url = isEditing
                ? `${process.env.REACT_APP_API_BASE_URL}/merchants/offer/${offer.offer_id}`
                : `${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/newoffer`;
            const method = isEditing ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(offer),
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                throw errorResponse;
            }
            return response.json();
        },
        onMutate: async (offer) => {
            await queryClient.cancelQueries(['offers', merchantID]);

            const previousOffers = queryClient.getQueryData(['offers', merchantID]);

            if (offer.offer_id) {
                queryClient.setQueryData(['offers', merchantID], (old) =>
                    old.map((existingOffer) =>
                        existingOffer.offer_id === offer.offer_id ? { ...existingOffer, ...offer } : existingOffer
                    )
                );
            } else {
                queryClient.setQueryData(['offers', merchantID], (old) => [
                    ...old,
                    { ...offer, id: Math.random() },
                ]);
            }

            return { previousOffers };
        },
        onSuccess: (data) => {
            if (data.updatedOffer) {
                toast.success(`Ponuda "${data.updatedOffer.name}" je uspešno izmenjena!`);
            } else if (data.newffer) {
                toast.success(`Ponuda "${data.newOffer.name}" je uspešno kreirana!`);
            } else {
                toast.success('Ponuda je uspešno obrađena!');
            }
        },
        onError: async (err, offer, context) => {
            try {
                if (err.missingFields) {
                    toast.error(
                        `Polja koja nedostaju su: ${err.missingFields.join(', ')}`
                    );
                } else if (err.error) {
                    toast.error(err.error);
                } else {
                    toast.error(
                        offer.offer_id
                            ? `Neuspešna izmena ponude "${offer.name}". Molim vas pokušajte ponovo.`
                            : `Neuspešno kreiranje ponude "${offer.name}". Molim vas pokušajte ponovo.`
                    );
                }
            } catch (parseError) {
                console.error('Error parsing the error response:', parseError);
                toast.error('Neočekivana greška. Molim vas pokušajte ponovo.');
            }
            if (context?.previousOffers) {
                queryClient.setQueryData(['offers', merchantID], context.previousOffers);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['offers', merchantID]);
        },
    });


    const handleEditClick = async (offerId) => {
        try {
            const offerDetails = await fetchOfferDetails(offerId);
            setSelectedOffer({ ...offerDetails, offer_id: offerId });
            setShowEditOfferModal(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    const handleCreateOffer = (newOffer) => {
        mutation.mutate(newOffer);
        setShowNewOfferModal(false);
    };

    const handleSaveOffer = (updatedOffer) => {
        mutation.mutate(updatedOffer);
        setShowEditOfferModal(false);
        setSelectedOffer(null);
    };

    const columns = [
        { headerName: 'ID ponude', field: 'offer_id', flex: 1, filter: true },
        { headerName: 'Naziv ponude', field: 'offer_title', flex: 1, filter: true },
        { headerName: 'Tip ponude', field: 'offer_type', flex: 1, filter: true },
        {
            headerName: 'Početak ponude',
            flex: 1,
            field: 'valid_from',
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }).format(new Date(params.value));
            }
        },
        {
            headerName: 'Kraj ponude',
            flex: 1,
            field: 'valid_to',
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }).format(new Date(params.value));
            }
        },
        {
            headerName: 'Maloprodajni objekat',
            flex: 1,
            field: 'physical_store',
            cellRenderer: (params) => {
                return (
                    <input
                        type="checkbox"
                        disabled
                        checked={params.value}
                    />
                );
            },
        },
        { headerName: 'Ukupan broj kupona', field: 'voucherDetails.totalVouchers', flex: 1 },
        { headerName: 'Aktivirani kuponi', field: 'voucherDetails.userAssignedVouchers', flex: 1 },
        { headerName: 'Iskorišćeni kuponi', field: 'voucherDetails.activeVouchers', flex: 1 },
        {
            headerName: '',
            flex: 1,
            cellRenderer: (params) => {
                const isInfoOffer = params.data.offer_type === 'Info';
                const offerId = params.data.offer_id;

                const dynamicUrl = `https://stage-intesa.payteam.live/offer-page?client=rsintesa&offerId=${offerId}&cif=12345`;

                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {user.role === 'INTESA_SUPER_ADMIN' && (
                            <FaEdit
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '5px',
                                    marginLeft: '5px',
                                    color: '#0d6efd',
                                    transition: 'color 0.3s',
                                }}
                                size={26}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#084298')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#0d6efd')}
                                onClick={() => handleEditClick(params.data.offer_id)}
                            />
                        )}

                        {user.role === 'INTESA_SUPER_ADMIN' && (
                            <div
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    color: '#f57c00',
                                    fontWeight: 'bold',
                                    fontSize: '24px',
                                    transition: 'color 0.3s',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#e65100')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#f57c00')}
                                onClick={() => window.open(dynamicUrl, '_blank')}
                            >
                                M
                            </div>
                        )}
                        {!isInfoOffer && (
                            <FaEye
                                style={{
                                    cursor: 'pointer',
                                    color: '#6c757d',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    transition: 'color 0.3s',
                                    marginTop: '5',
                                }}
                                size={26}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#495057')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#6c757d')}
                                onClick={() => navigate(`/merchants/${merchantID}/offers/${params.data.offer_id}`)}
                            />
                        )}
                    </div>
                );
            },
        }
    ];

    return (
        <div className="offers">
            {isLoading && <div>Učitava se...</div>}
            {isError && <div>Greška prilikom učitavanja ponuda.</div>}
            {!isLoading && !isError && (
                <div className="ag-theme-quartz" style={{ height: 400 }}>
                    {offers && offers.length > 0 ? (
                        <AgGridReact
                            rowData={offers}
                            columnDefs={columns}
                            pagination={true}
                            paginationPageSize={20}
                            enableCellTextSelection={true}
                            localeText={localeText}
                        />
                    ) : (
                        <div>Nema dostupnih ponuda.</div>
                    )}
                </div>
            )}
            <NewOfferModal
                show={showNewOfferModal}
                handleClose={() => setShowNewOfferModal(false)}
                onSubmit={handleCreateOffer}
            />
            <EditOfferModal
                show={showEditOfferModal}
                handleClose={() => setShowEditOfferModal(false)}
                onSubmit={handleSaveOffer}
                offerData={selectedOffer}
            />
            {user.role === 'INTESA_SUPER_ADMIN' && (
                <div className="new-offer-button mt-3">
                    <Button variant="primary" onClick={() => setShowNewOfferModal(true)}>
                        Nova ponuda
                    </Button>
                </div>
            )}
        </div>
    );
};

export default OffersTable;

import React, { useState, useEffect } from 'react';

const Sidebar = ({ categories }) => {
    const [activeCategory, setActiveCategory] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const categoryElements = categories.map(category => document.getElementById(category));

            for (const el of categoryElements) {
                if (el) {
                    const rect = el.getBoundingClientRect();
                    if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
                        setActiveCategory(el.id);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [categories]);

    return (
        <div className="sidebar">
            <ul>
                {categories.map((category) => (
                    <li
                        key={category}
                        className={activeCategory === category ? 'active' : ''}
                        onClick={() => {
                            const element = document.getElementById(category);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                                setActiveCategory(category);
                            } else {
                                console.log(`No element found for ${category}`);
                            }
                        }}
                    >
                        {category}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;

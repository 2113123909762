import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

function NewMerchantModal({ show, onHide, onSubmit }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [charCount, setCharCount] = useState({
        title: 0,
        shortDescription: 0,
        longDescription: 0,
        physicalStoreDesc: 0,
    });

    const handleInputChange = (field, value) => {
        setCharCount((prev) => ({
            ...prev,
            [field]: value.length,
        }));
    };

    const handleFormSubmit = (data) => {

        const newMerchant = {
            title: data.companyName,
            category: data.category,
            service_order: data.serviceOrder,
            short_description: data.shortDescription,
            long_description: data.longDescription,
            small_image_url: data.logo,
            large_image_url: data.heroImage,
            logo_color_code: data.logoColorCode,
            active: data.active === "true",
            physical_store_desc: data.physicalStoreDesc,
            intesa_merchant_cif: data.intesaMerchantCif,
        };

        onSubmit(newMerchant);
        reset();
        onHide();
    };

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Merchant
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="mb-3">
                        <label htmlFor="companyName" className="form-label">Company Name</label>
                        <input
                            id="companyName"
                            className="form-control"
                            {...register('companyName', { required: true })}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                        />
                        <div className="text-muted">{charCount.title}/20 characters</div>
                        {errors.companyName && <span className="text-danger">Company name is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="category" className="form-label">Category</label>
                        <select
                            id="category"
                            className="form-control"
                            {...register('category', { required: true })}
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select a Category
                            </option>
                            <option value="Auto moto svet">Auto moto svet</option>
                            <option value="Lepota i zdravlje">Lepota i zdravlje</option>
                            <option value="Moda">Moda</option>
                            <option value="Gorivo">Gorivo</option>
                            <option value="Opremanje doma i elektronika">Opremanje doma i elektronika</option>
                            <option value="Dečiji kutak">Dečiji kutak</option>
                            <option value="Lifestyle">Lifestyle</option>
                            <option value="Kućni ljubimci">Kućni ljubimci</option>
                            <option value="Putovanja">Putovanja</option>
                            <option value="Pretplate i digitalne usluge">Pretplate i digitalne usluge</option>
                            <option value="Restorani i barovi">Restorani i barovi</option>
                            <option value="Namirnice">Namirnice</option>
                            <option value="Kladionice">Kladionice</option>
                            <option value="Ostalo">Ostalo</option>
                        </select>
                        {errors.category && <span className="text-danger">Category is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="serviceOrder" className="form-label">Service Order</label>
                        <input
                            id="serviceOrder"
                            className="form-control"
                            {...register('serviceOrder', { required: true })}
                        />
                        {errors.serviceOrder && <span className="text-danger">Service order is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="shortDescription" className="form-label">Short Description</label>
                        <textarea
                            id="shortDescription"
                            className="form-control"
                            {...register('shortDescription', { required: true })}
                            onChange={(e) => handleInputChange('shortDescription', e.target.value)}
                        />
                        <div className="text-muted">{charCount.shortDescription}/200 characters</div>
                        {errors.shortDescription && <span className="text-danger">Short description is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="longDescription" className="form-label">Long Description</label>
                        <textarea
                            id="longDescription"
                            rows="5"
                            className="form-control"
                            {...register('longDescription', { required: true })}
                            onChange={(e) => handleInputChange('longDescription', e.target.value)}
                        />
                        <div className="text-muted">{charCount.longDescription}/2000 characters</div>
                        {errors.longDescription && <span className="text-danger">Long description is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="physicalStoreDesc" className="form-label">Location - Physical Store Description</label>
                        <textarea
                            id="physicalStoreDesc"
                            rows="5"
                            className="form-control"
                            {...register('physicalStoreDesc')}
                            onChange={(e) => handleInputChange('physicalStoreDesc', e.target.value)}
                        />
                        <div className="text-muted">{charCount.physicalStoreDesc}/2000 characters</div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="logo" className="form-label">Logo URL</label>
                        <input
                            id="logo"
                            className="form-control"
                            {...register('logo', { required: true })}
                        />
                        {errors.logo && <span className="text-danger">Logo is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="heroImage" className="form-label">Hero Image URL</label>
                        <input
                            id="heroImage"
                            className="form-control"
                            {...register('heroImage', { required: true })}
                        />
                        {errors.heroImage && <span className="text-danger">Hero Image is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="logoColouCode" className="form-label">Logo Color Code</label>
                        <input
                            id="logoColouCode"
                            className="form-control"
                            {...register('logoColorCode', { required: false })}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="intesaMerchantCif" className="form-label">Intesa Merchant CIF</label>
                        <input
                            id="intesaMerchantCif"
                            className="form-control"
                            {...register('intesaMerchantCif', { required: false })}
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Is Active</label>
                        <div className="form-check">
                            <input
                                type="radio"
                                id="active-true"
                                className="form-check-input"
                                value="true"
                                {...register('active', { required: true })}
                            />
                            <label htmlFor="active-true" className="form-check-label">Active</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="radio"
                                id="active-false"
                                className="form-check-input"
                                value="false"
                                {...register('active', { required: true })}
                            />
                            <label htmlFor="active-false" className="form-check-label">Inactive</label>
                        </div>
                        {errors.active && <span className="text-danger">Please select the active status</span>}
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="secondary" onClick={onHide} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default NewMerchantModal;